<template>
  <div>
    <b-navbar toggleable="lg" class="top-bar" fixed="top">
      <img
        v-b-toggle.sidebar-no-header
        @click="getTotalQemus()"
        alt="Menú"
        src="../assets/menu.svg"
      />

      <b-navbar-brand class="md-mx-auto brand pointer" @click="goToHome"
        ><img style="max-height: 90%" src="../assets/images/logo_virtualio.png"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!--<b-nav-item href="#">Link</b-nav-item>-->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--   <b-nav-form>
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              >Search</b-button
            >
          </b-nav-form>

          <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown>
-->       
          <div class="pr-3 d-flex align-content-center flex-wrap text-cente">
              <em>VLAN: {{ Number(user.pool.substring(6, 9)) + Number(1000) }}</em>
          </div>
<div class="pr-3 d-flex align-content-center flex-wrap text-cente">
              <em>Network: 10.0.{{(user.pool.substring(6, 9))}}.0/24</em>
          </div>       
          <div class="pr-2 d-flex align-content-center flex-wrap text-cente">
              <em>{{ user.pool }}</em>
          </div>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ user.name }}</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-sidebar
      id="sidebar-no-header"
      aria-labelledby="sidebar-no-header-title"
      title="Virtualio."
      shadow
      backdrop
    >
      <template #footer>
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          Total màquines:
        </div>
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <b-progress :max="max" height="2rem" class="footer-side-bar">
            <b-progress-bar :value="value">
              <span>
                <strong>{{ value }} / {{ max }}</strong></span
              >
            </b-progress-bar>
          </b-progress>
        </div>
      </template>

      <div class="px-3 py-2">
        <b-container fluid class="p-4" style="text-align: left">
          <!-- <b-row align-h="start">
            <b-col>
              <router-link to="/qemu">
                <img src="../assets/add.svg" />
                Crear nou recurs</router-link
              >
            </b-col>
          </b-row>
          <br />-->
          <b-row align-h="start">
            <b-col>
              <router-link to="/dashboard">
                <img src="../assets/dashboard.svg" /> Dashboard
              </router-link>
            </b-col>
          </b-row>
          <br />
          <b-row align-h="start">
            <b-col>
              <router-link to="/bundle">
                <img src="../assets/resources.svg" /> Tots els recursos
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      user: "user",
    }),
  },
  created() {
    this.getQemus();
    this.timer = setInterval(this.getQemus, 10000);
  },
  data() {
    return { value: 0, timer: "", max: process.env.VUE_APP_MAX_QEMUS };
  },
  methods: {
    logout() {
      this.$store.commit("logout", "");
      this.$router.push("login");
    },
    getTotalQemus() {
      this.value = this.$store.state.qemus.length;
    },
    goToHome() {
      this.$router.push({ path: "/dashboard" }).catch((err) => {});
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
     async getQemus() {
      await this.$apiCloud("vms", {
        method: "GET",
      })
        .then((response) => {
          this.$store.commit("setQemus", response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

</script>
<style>
.navbar {
  padding-bottom: 1rem;
}
.footer-side-bar {
  width: -webkit-fill-available;
  margin-bottom: 15px;
}
.top-bar {
  background-color: #0078d4;
}
.brand {
  font-size: 15px;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  color: #fff !important;
}
.menu {
  width: 1%;
}
</style>
